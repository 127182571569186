// src/Popup.jsx
import React, {useState, useEffect } from 'react';
import './Popup.css';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../Context/UserAuthContext';
import { Toaster } from 'react-hot-toast';
import toaster from '../../Objects/toaster';
const Popup = ({ title, submenu, onClose,showInput,mode,btnText,temp}) => {
  const {addTemplates,deleteTemplate}=useUserAuth();
  const [newMenus,setNewMenus]=useState([])
  const [newsub,setNewsub]= useState({title:'',_id:''});
  const navigate = useNavigate();
  useEffect(()=>{
    setNewMenus(submenu)
  },[])
  const handleSubmenuClick = (item) => {
    // Implement your logic here
    if(mode === 'template'){
      navigate(`/template/add/${item.title}/${item._id}`)
    }else{
      navigate(`/template/${item.title}/add/content`)
    }
   
    //navigate(`/template/${item}/add/content`)
   // onClose(); // Close the popup after clicking
  };
  const handleDeleteTemplate=async (item)=>{
  let res = await deleteTemplate(item._id);
  console.log(res)
  }
const handleChangeName=(e)=>{
  const newValue = e.target.value.trim();
  setNewsub({title:newValue})
    // if (newValue !== "") {
    //  // setNewMenus((prevMenus) => [...prevMenus, newValue]);
    //   // Optionally, clear the input field after adding
    //   e.target.value = "";
    // }
}
const handleSubmit=async()=>{
  //setNewMenus((prevMenus) => [...prevMenus, newsub]);
  setNewsub({title:''})
  let obj = {}
  let tempArr =[]
  //object creation
  obj["title"] = newsub.title;
  obj["description"] = "Adding Templates";
  obj["isDraft"] = false;
  obj["data"] = {};
  tempArr.push(obj);
  let newObj  = { tempArr : tempArr }
  let res = await addTemplates(newObj);
  console.log(res)
  if(res.data.error){
    toaster(res.data.message,"error")
  }else{
    setNewMenus((prevMenus) => [...prevMenus, {title:res.data.data.title,_id:res.data.data._id}]);
  }
 //
}
const handleClose=()=>{
  onClose()
}
  return (
    <div className="popup-overlay" onClick={handleClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={handleClose}>
          &times;
        </span>
        <h2>{title}</h2>
        {showInput ? 
        <div className="smlform">
        <label>Enter Template Name</label>
        <input type="text" placeholder='Enter name' value={newsub.title} onChange={handleChangeName}/>
        <button onClick={handleSubmit}>Add</button>
        </div>:
        ""}
        <ul className="submenu">
          {newMenus.map((item, index) => (
            <li key={index}>
              <a>
                {`${index+1}. ${item.title}`} 
               {/* <button onClick={() => handleDeleteTemplate(item)} className='btnSubmit danger sml'>Delete</button>  */}
                <button onClick={() => handleSubmenuClick(item)} className='btnSubmit danger sml'>{btnText}</button>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <Toaster/>
    </div>
  );
};

export default Popup;
