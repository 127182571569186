import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { DeepListTypes } from '../../Objects/Country';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../Context/UserAuthContext';
import AsyncSelect from 'react-select/async';
import CustomTabCampaign from './Tab/CustomTabCampaign';

const CreateCampaign = () => {
  const navigate = useNavigate();
  const [meta,setMeta]=useState({type:{},deepLink:"",contents:[],flag:false,subscriptions:[],upcomings:[]})
  useEffect(()=>{
//getAllContents();
  },[])
  
  const handleFieldChange = (e) => {
    setMeta({
      ...meta,
      type: e.value,
      subscriptions: [],
      upcomings: [],
      contents: [],
    });
  };
  
  const CreateDeepLink=()=>{
    console.log(meta)
    const prefix = "http://oyehoye.xyz"
    if(meta.type === 'Content'){
      //https://www.jalva.app/contents/Muzaffarnagar%20-%20The%20Burning%20Love
      setMeta({...meta,deepLink:`${prefix}/contents/${meta.contents[0].title}`})
    }else if(meta.type === 'subscription'){
      setMeta({...meta,deepLink:`${prefix}/subscription`})
    }else{
      setMeta({...meta,deepLink:`${prefix}/upcomings/${meta.upcomings[0].title}`})
    }
  }

  
  return (
    <div className='newBuild'>
        {/* <CustomTabCampaign/> */}
    {/* <label>Google | Facebook</label>
    <button className='btnSubmit' onClick={CreateDeepLink}>Create</button>
    <button className='btnSubmit btn-danger' onClick={()=>navigate(-1)}>Back</button> */}
    </div>
  )
}

export default CreateCampaign