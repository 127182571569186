// src/components/MoviesList.js
import React, { useEffect } from 'react';
import imenu from "../assets/menu-burger.png";
const MoviesList = ({icon}) => {
  return (
    <div className='content subcr'>
      <div className='path'>
              <span className="head">
                <span className="dashhead m-3">
                <img src={imenu} alt="menu" onClick={icon}/>
                  <h3>Home | Ads Management </h3>
                </span>
              </span>
      </div>
    </div>
  );
};

export default MoviesList;
