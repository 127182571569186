import React, { useEffect } from 'react'
import iarrow from "../../assets/right-arrow.png";
import ioption from "../../assets/menu.png"
import { useState } from 'react';
import Popup from './Popup';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useUserAuth } from '../Context/UserAuthContext';
import imenu from "../../assets/menu-burger.png";
const Multiplecontents = ({icon}) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const {listTemplates}=useUserAuth();
  const [path,setPath]=useState("");
  const [temp,setTemp]=useState([])
  const [draft,setDrafts]=useState([])
  let router = useNavigate();
  useEffect(()=>{
    const fetchData=async()=>{
      let type="draft"
      let res = await listTemplates(type)
      console.log(res.data.data)
      let rData = res.data.data
      setTemp(rData);
      setDrafts(rData.filter(x=>x.isDraft))
    }
    fetchData()
  },[])
  const menuItems = [
    {
      name: 'Add Contents',
      submenu:temp,
      showInput:false,
      mode:'content',
      btnText:'Choose'
    },
    {
      name: 'Show All Contents',
      submenu: [],
      showInput:false,
      btnText:'Edit'
    },
    {
      name: 'Create Templates',
      submenu: [],
      showInput:true,
      mode:'template',
      btnText:'Edit'
    },
    {
      name: 'All Templates',
      submenu: temp,
      showInput:false,
      mode:'template',
      btnText:'Edit'
    },
  ];

  const handleMenuClick = (item) => {
    if(item.name === "Show All Contents"){
      router("/content/1/true")
    }
    setPath(item.mode)
    setActiveMenu(activeMenu === item.name ? null : item.name);
  };
  return (
    <div className="content subcr">
         <div className='path'>
              <span className="head">
                <span className="dashhead m-3">
                <img src={imenu} alt="menu" onClick={icon}/>
                  <h3>Home | Manage Contents</h3>
                </span>
              </span>
          </div>
        <ul className='newContent'>
        {menuItems.map((item) => (
          <li key={item.name} className="menu-item">
            <a
              href="#"
              className={`main-link ${activeMenu === item.name ? 'active' : ''}`}
              onClick={() => handleMenuClick(item)}
              aria-haspopup="true"
              aria-expanded={activeMenu === item.name}
            >
              {item.name}
              <img src={iarrow}/>
            </a>
          </li>
        ))}

        </ul>

        <h2>Your Draft</h2>
        <ul className='newContent'>
          {draft.map((x,index)=>{
            return(
              <li><a href="#">{x.title} <img src={ioption} className='optionImg'/></a></li>
            )
          })}
        </ul>

        {activeMenu && activeMenu !=="Show All Contents" && (
        <Popup
          title={activeMenu}
          submenu={
            menuItems.find((item) => item.name === activeMenu).submenu
          }
          showInput={ menuItems.find((item) => item.name === activeMenu).showInput}
          btnText={ menuItems.find((item) => item.name === activeMenu).btnText}
          onClose={() => setActiveMenu(null)}
          mode={path}
          temp={temp}
        />
      )}
    </div>   
  )
}

export default Multiplecontents