import React from 'react'
import { useEffect,useState } from 'react';
import AsyncSelect from 'react-select/async';
import Income from '../Line/Income';
import 'react-tippy/dist/tippy.css'
import {
  Tooltip,
} from 'react-tippy';
import idot from "../../../assets/menu.png"
import { Toaster } from 'react-hot-toast';
import toaster from '../../../Objects/toaster';
import { useUserAuth } from '../../Context/UserAuthContext';
const Downloads= ({income,handleDates,customData}) => {
    const { getChartData } = useUserAuth();
    const [activeiIndex,setiActiveIndex]=useState(1)
    const [loading, setLoading] = useState(true);
    const [options,setOptions]=useState(["Yesterday","Today","Week","Biweek","Month","Quarter","Halfyear","Year","Custom"])
    const [defaultOption,setDefaultOption]=useState({label:'Android'});
    const [selectedstartDate, setstartSelectedDate] = useState("");
    const [selectedendDate, setendSelectedDate] = useState("");
    const [realdata,setRealData]=useState([])
    const [metaData,setMetaData]=useState({})
    const [startDate,setStartDate]=useState('');
    const [endDate,setEndDate]=useState('');
    const option_platforms=async()=>{
      return [
        {
          label:'Android Phone'
      },
      {
          label:'Website'
      },
      {
          label:'IOS'
      },
      {
          label:'Android TV'
      }
      ]
    }
    const [oflag,setOflag]=useState(false)
    let API_URL='';
    const loadGraphData=async(newUrl,index)=>{
      setLoading(true)
      API_URL = '/dashboardJalwa?platform=Android Phone&type=download';
      if(newUrl){
        API_URL = newUrl;
      }
      
      setiActiveIndex(index)
      let RealData = await getChartData(API_URL);

      let altValue = options[index].toLowerCase();
      setMetaData(RealData.data.data.download)
      setRealData(RealData.data.data.download[altValue])
      setLoading(false)
      }

      useEffect(()=>{
        loadGraphData(null,1)
      },[])

    // useEffect(()=>{
     
    // },[selectedendDate])

    const handleDateSelection=(x,index)=>{
      if(!metaData.today){
        loadGraphData(null,1)
        return 
      }
      let alteredVal = x.toLowerCase();
      setRealData(metaData[alteredVal])
      setiActiveIndex(index)
      if(index === 8){
        setOflag(true)
      }else{
        setOflag(false)
      }
    }
    const handleDateChangeR = (e,int) => {
      let val = e.target.value;
        if(int !== 0){
          setendSelectedDate(val);
        //  checkForCustom(selectedstartDate,val)
          if(selectedstartDate !== ""){
            if(val < selectedstartDate){
              toaster("Please make sure end date greater then start date, Invalid Dates !","error")
            }else{
              checkForCustom(selectedstartDate,val)
            }
          }
        }else{
          setstartSelectedDate(val);
          if(selectedendDate !== ""){
            if(val > selectedendDate){
              toaster("Please make sure start date lesser then end date, Invalid Dates !","error")
            }else{
              checkForCustom(val,selectedendDate)
            }
          }
        }
    };
    const checkForCustom=(start,end)=>{
      const sdateStr = start;
      const edatestr = end;
      const sepochTime = new Date(sdateStr).getTime();
      const eepochTime = new Date(edatestr).getTime();
      setStartDate(sepochTime)
      setEndDate(eepochTime)
      loadGraphData(`/dashboardJalwa?platform=Android Phone&startTime=${sepochTime}&endTime=${eepochTime}&timeFrame=custom&type=download`,8)
    }
      const handleplatform=(e)=>{
        if(activeiIndex === 8){
          loadGraphData(`/dashboardJalwa?platform=${e.label}&startTime=${startDate}&endTime=${endDate}&timeFrame=custom&type=download`,8)
        }else{
          loadGraphData(`/dashboardJalwa?platform=${e.label}&type=download`,activeiIndex)
        }
        
       // setdeviceFiltered(Array.isArray(e)?e.map(x=>x.label):[]);
      }

  return (
    <div className='grchild'>
    <div className={oflag ? 'grdatepicker':'grdatepicker hidden'}>
          <div className='grdatepickerch'>
            <p>Select Start Date</p>
            <input
            type="date"
            value={selectedstartDate}
            onChange={(e)=>handleDateChangeR(e,0)}
            dateFormat="dd-MM-yyyy"
            />
          </div>
          <div className='grdatepickerch'>
            <p>Select End Date</p>
            <input
              type="date"
              value={selectedendDate}
              onChange={(e)=>handleDateChangeR(e,1)}
            />
          </div>
    </div>
    <div className="grsub">
      <h2>Downloads <br/><p>Exclusive Data</p></h2>
      <AsyncSelect
      className="grselect"
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.label}
                  getOptionValue={e => e.label}
                  defaultValue={defaultOption}
                  loadOptions={option_platforms}
                  onChange={handleplatform}
                  placeholder='Select Platform'
      />
      <ul>
        {options.map((x,index)=>{
          return <li key={index} className={index === activeiIndex ? "active":"inactive"} onClick={()=>handleDateSelection(x,index)}>{x}</li>
        })}
        <Tooltip
          // options
          title="Options"
          position="top"
          trigger="mouseenter"
        >
        <img src={idot}/>
        </Tooltip>
        <div>
      </div>
      </ul>
    </div>
    <div className='grvertical'>
    {loading ? 
    <div className="skeleton"></div>:
    <Income width={1200} graphData={realdata} height={400} type={"download"}/>}
    </div>
    <Toaster/>
  </div>
  )
}

export default Downloads