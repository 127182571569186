import React, { useState } from 'react';
import Select from 'react-select';
import { PolicyOptions} from '../../Objects/Country';
import PrivacyPolicy from './PoliciesTypes/PrivacyPolicy';
import RefundPolicy from './PoliciesTypes/RefundPolicy';
import TermsAndCondition from './PoliciesTypes/TermsAndCondition';
import GrievanceRedressal from './PoliciesTypes/GrievancesRedressal';
import toaster from '../../Objects/toaster';
import { Toaster } from 'react-hot-toast';
import {useNavigate } from 'react-router-dom';
import imenu from "../../assets/menu-burger.png";
const PolicyGenerator = ({icon}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: '',
    website: '',
    contactEmail: '',
    refundPeriod: '',
    contactNumber:'',
    name:'',
    type:'',
  });

  const [generatedPolicy, setGeneratedPolicy] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const generatePolicy = () => {
    const { companyName, website, contactEmail, refundPeriod,name } = formData;
    let fetchedValue = "";
    if(companyName !== '' && contactEmail !== ""){
      switch(formData.type){
        case "Privacy Policy":
            fetchedValue = PrivacyPolicy(companyName,website,contactEmail)
            setGeneratedPolicy(fetchedValue);
            break;
        case "Refund Policy":
            if(refundPeriod !== '' && refundPeriod > 0){
              fetchedValue = RefundPolicy(refundPeriod)
              setGeneratedPolicy(fetchedValue);
              break;
            }else{
              toaster("Please Add Refund Period","error")  
              break;
            }
        case "Terms and Condition":
            fetchedValue = TermsAndCondition(companyName,contactEmail)
            setGeneratedPolicy(fetchedValue);
            break;
        case "Grievances Redressal":
            if(name !== ''){
              fetchedValue = GrievanceRedressal(companyName,name,contactEmail)
              setGeneratedPolicy(fetchedValue);
              break;
            }else{
              toaster("Please Add Officer Name","error")  
              break;
            }
        default:
          toaster("Please Select Type !","error") 
            break;
    }
    }else{
      toaster("Please Fill Company Name & Contact Email","error")  
    }
  };

  const handleDownload = () => {
    const blob = new Blob([generatedPolicy], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Policies.html';
    link.click();
  };
  const handleFieldChange = (e) => {
    setFormData({ ...formData, type: e.value });
  };
  return (
    <div className='content subcr'>
      <div className='path'>
              <span className="head">
                <span className="dashhead m-3">
                <img src={imenu} alt="menu" onClick={icon}/>
                  <h3>Home | Policies </h3>
                </span>
              </span>
      </div>
      <div className='newBuild'>
      <div>
        <label>Select Type:</label>
        <Select className="react-select-container"  options={PolicyOptions} onChange={(e)=>handleFieldChange(e)}></Select>
        <label>Company Name:</label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div>
        <label>Website:</label>
        <input
          type="text"
          name="website"
          value={formData.website}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div>
        <label>Contact Email:</label>
        <input
          type="email"
          name="contactEmail"
          value={formData.contactEmail}
          className="form-control"
          onChange={handleInputChange}
        />
      </div>
      {formData.type === 'Refund Policy' ?
      <div>
        <label>Refund Period (days):</label>
        <input
          type="number"
          name="refundPeriod"
          value={formData.refundPeriod}
          className="form-control"
          onChange={handleInputChange}
        />
      </div>:''}
      {formData.type === 'Grievances Redressal' ?
      <div>
        <label>Officer Name :</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          className="form-control"
          onChange={handleInputChange}
        />
      </div>:''}
      <button className="btnSubmit primary" onClick={generatePolicy}>Generate Policy</button>
      <button className="btnSubmit danger" onClick={()=>navigate(-1)}>Back</button>
      {generatedPolicy && (
        <div className="policyoutput">
        <pre>
            {`${generatedPolicy}`}
        </pre>
        <button className="btnSubmit primary" onClick={handleDownload}>Download as File</button>
        </div>
      )}
      <Toaster/>
      </div>
    </div>
  );
};

export default PolicyGenerator;
