import React, { useEffect, useState,useRef } from 'react'
import imenu from "../../assets/menu-burger.png";
import { useUserAuth } from '../Context/UserAuthContext';
import ioption from "../../assets/apps.png";
import { useNavigate } from 'react-router-dom';
import alertinstance from '../../Objects/Alert';
const ListVoucher = ({icon}) => {
  const { listVoucherCode,deleteVoucherCode } = useUserAuth();
  const [list,setList]=useState([])
  const [currstate,setCurrstate]=useState([])
  const [hover,sethover]=useState(false);
  const [position, setPosition] = useState({ top: 70, left: 1000 });
   const popupRef = useRef(null);
  useEffect(()=>{
    const fetchData = async()=>{
    let res = await listVoucherCode();
    //console.log(res)
    setList(res.data.data)
    }
    fetchData();
  },[])
   useEffect(() => {
        if (hover) {
          document.addEventListener("mousedown", handleClickOutside);
        } else {
          document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [hover]);
  const DeleteVoucherCode=async ()=>{
    let res = await deleteVoucherCode({voucherId:currstate._id})
    alertinstance(res)
    setList((list)=>list.filter(x=> x._id !== currstate._id))
    //window.location.reload()
  }
  const manipulateDate=(epochTime)=>{
    const date = new Date(epochTime);
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear(); // Get the full year

    return `${day}-${month}-${year}`;
  }
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      sethover(false); // Hide the popup
    }
  };
  let stylePopup = {
    marginTop:position.top,
    marginLeft:position.left,
}
const checkcontent=(event,q)=>{
  setCurrstate(q)
  sethover((hov) => !hov)
  const buttonPosition = event.target.getBoundingClientRect(); // Get button position
  const { innerWidth, innerHeight } = window;
  let top = buttonPosition.top - 120; // Suggested top position
  let left = buttonPosition.left - 320; // Suggested left position
  // Adjust to stay within the viewport
  if (top < 0) top = 10; // Prevent overflow at the top
  if (left < 0) left = 10;
  let popupHeight = 300;
  let popupWidth = 200; // Prevent overflow on the left
  if (top + popupHeight > innerHeight) top = innerHeight - popupHeight - 80; // Prevent overflow at the bottom
  if (left + popupWidth > innerWidth) left = innerWidth - popupWidth - 200; // Prevent overflow on the right
  setPosition({ top, left });
}

const navigate = useNavigate();
  return (
    <div className='content subcr'>
            <div className='path'>
              <span className="head">
                <span className="dashhead m-3">
                <img src={imenu} alt="menu" onClick={icon}/>
                  <h3>Home | Voucher Codes </h3>
                </span>
              </span>
            </div>
             <div className={hover ? 'list shadow':'listnone shadow'} style={stylePopup} ref={popupRef}>
                  <li onClick={()=> navigate(`/voucher/update/${currstate._id}`)}>
                  <span>Update Voucher</span></li><br/>
                  <li onClick={()=>DeleteVoucherCode()}>
                  <span>Delete Voucher</span></li><br/>
              </div>
            <table className="table table-striped">
                    <thead>
                      <tr>
                      <th scope="col">No</th>
                        <th scope="col">Code</th>
                        <th scope="col">Expires In</th>
                        <th scope="col">Status</th>
                       </tr>
                    </thead>
                    <tbody>
                    {list.map((q,index)=>{
                            return(                              
                                <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{q.code}</td>
                                <td>{manipulateDate(q.expiresIn)}</td>
                                <td>{q.active ? 'active':'inactive'}</td>
                                <td> <img src={ioption} className='menu'
                                onClick={(e) => checkcontent(e,q)}/></td>
                                </tr>
                            )})} 
                    </tbody>
              </table>
    </div>
  )
}

export default ListVoucher