import React, { useEffect, useState } from 'react';
import '../css/App.css';
import '../css/Home.css';
import imenu from "../assets/menu-burger.png";
import iuser from "../assets/users.png";
import Bargraph from './Graphs/Bar/Bargraph';
import Piegraph from './Graphs/Pie/Piegraph';
import 'react-tippy/dist/tippy.css'
import {
  Tooltip,
} from 'react-tippy';
import { useUserAuth } from './Context/UserAuthContext';
import Revenue from './Graphs/Revenue/Revenue';
import Users from './Graphs/Usersgrp/Users';
import Downloads from './Graphs/Downloadsgrp/Downloads';
import Googlegrp from './Graphs/Analytics/Googlegrp';
import Facebookgrp from './Graphs/Analytics/Facebookgrp';
import Packagescount from './Graphs/Subscriptiongrp/Packagescount';
import Dashedlinegraph from './Graphs/Line/Dashedlinegraph';
const Dashboard = ({ icon }) => {
  const [role,setRole]=useState("")
  const [email,setEmail]=useState("")
  useEffect(()=>{
    setRole(localStorage.getItem('role'))
    setEmail(localStorage.getItem('email'))
  },[])
  
  return (
    <div className="subcr content">
       <div className='path'>
        <span className="head">
        <span className="dashhead m-3">
        <img src={imenu} alt="menu" onClick={icon}/>
          <h3>Home | Dashboard</h3>
        </span>
        <span className="userhead">
          <p style={{ fontWeight: '500' }}>
            <img src={iuser} alt="menu"/> {email} <br />Role - {role}
          </p>
        </span>
      </span> 
      </div>
      <div className="grcontainer">
        <div className='grchild'>
          <div className="grsub">
            <h2>Top <span>6</span> Trending Contents<br/><p>Exclusive Data collected from  <span>Sept 25 - Dec 25</span></p></h2>
            <h2></h2>
            <h2></h2>
            <h2 style={{'marginLeft':'-100px'}}>Users Aquired <br/><p>Data collected from various Sources of past <span>4 month</span></p></h2>
          </div>
          <div className='grhorizontal'>
            <Dashedlinegraph width={580} height={400}/>
            <Piegraph width={580} height={400}/>
          </div>
        </div>
        <Revenue />
        <Users/>
        <Downloads/>
        <Packagescount/>
        <Googlegrp/>
        <Facebookgrp/>
      </div>
      <p className="versioncontrol">OYEHOYE CMS - v0.0.2 - Prod</p>
    </div>
  )
};

export default Dashboard;
